import React from "react";

function ShowFullInfoIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#7367F0" opacity="0.2" rx="6"/>
            <path
                fill="#7367F0"
                d="M13.631 11.998l-4.49 4.489a.65.65 0 00-.19.464c0 .176.067.34.19.464l.394.393A.651.651 0 0010 18c.176 0 .341-.068.464-.192l5.346-5.345a.65.65 0 00.191-.465.651.651 0 00-.191-.466l-5.34-5.34A.651.651 0 0010.004 6a.651.651 0 00-.464.192l-.393.393a.657.657 0 000 .928l4.484 4.485z"
            />
        </svg>
    );
}

export default ShowFullInfoIcon;
