import React, {useEffect} from 'react';
import "./styles.scss"

const GlobalError = ({children, isVisible, type}) => {

    return (
        <div className="global-error"
             style={{display: isVisible ? 'inlineBlock' : 'none'}}
        >
            <div className={`global-error__wrapper ${type === "success" ? "success-mes" : ''}`}>
                {
                    children
                }
            </div>
        </div>
    );
};

export default GlobalError;
