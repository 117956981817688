import "./index.scss";
import Nav from "../../components/Nav";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "../../assets/icons/EditIcon";
import EditButton from "../../components/EditButton";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import GlobalError from "../../components/GlobalError";
import Loader from "../../components/Loader";
import {useGet} from "../../api/request";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import ShowFullInfoIcon from "../../assets/icons/ShowFullInfoIcon";
import ShowMoreButton from "../../components/ShowMoreButton";
import Modal from "../../components/Modal";
import EyeIcon from "../../assets/icons/EyeIcon";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import {useAlert} from "../../components/AlertNotification";

const Сustomers = () => {

    const getU = useGet();
    const alertU = useAlert();

    const [isLoading, setIsLoading] = useState(true);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [applications, setApplications] = useState([]);

    const [modalStatus, setModalStatus] = useState({
        isActive: false,
        id: 0,
    });

    const loadApplications = () => {
        setIsLoading(true);

        let endpoint = `applications?page=${page + 1}&limit=${limit}&status=Получен контакт`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setApplications(resp.data.applications);
                    setCount(resp.data.totalCount);
                }
            })
            .catch(() => {
                setApplications([]);
                setCount(0);
                alertU({
                    status: 'error',
                    title: 'Получение заявок',
                    message: 'Произошла ошибка при попытке получить заявки'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        loadApplications();
    }, [page, limit]);


    return (
        <>
            <div className="applications">
                <div className="applications__header">
                    <Nav/>
                </div>
                <div className="applications__list">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: 80}} style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "15px",
                                    letterSpacing: "1px",
                                    color: "#5E5873",
                                    fontFamily: 'Poppins, sans-serif',
                                    textAlign: 'center'

                                }}>
                                    № Заявки
                                </TableCell>
                                <TableCell style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "15px",
                                    letterSpacing: "1px",
                                    color: "#5E5873",
                                    fontFamily: 'Poppins, sans-serif',
                                    textAlign: 'center'
                                }}>
                                    Статус
                                </TableCell>
                                <TableCell style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "15px",
                                    letterSpacing: "1px",
                                    color: "#5E5873",
                                    fontFamily: 'Poppins, sans-serif',
                                    textAlign: 'center'
                                }}>
                                    Сообщение
                                </TableCell>
                                <TableCell style={{width: 30}}>

                                </TableCell>
                                <TableCell style={{width: 30}}>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applications?.map((item) => (
                                <TableRow key={item?.id} hover={false}>
                                    <TableCell style={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "21px",
                                        color: "#6E6B7B",
                                        fontFamily: 'Poppins, sans-serif'
                                    }}>
                                        Заявка №{item?.id || '---'}
                                    </TableCell>
                                    <TableCell style={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "21px",
                                        color: "#6E6B7B",
                                        fontFamily: 'Poppins, sans-serif'
                                    }}>
                                        {item?.status || '---'}
                                    </TableCell>
                                    <TableCell style={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "21px",
                                        color: "#6E6B7B",
                                        fontFamily: 'Poppins, sans-serif'
                                    }}>
                                        {item.message || '---'}
                                    </TableCell>
                                    <TableCell style={{width: 30}}>
                                        <Link to={`/app/main/edit-report/${item.id}`}>
                                            <div className="scaleIcon">
                                                <EyeIcon/>
                                            </div>
                                        </Link>
                                    </TableCell>
                                    <TableCell style={{width: 30}}>
                                        <div className="scaleIcon"
                                             onClick={() => setModalStatus({
                                                 id: item.id,
                                                 isActive: true
                                             })}
                                        >
                                            <ShowFullInfoIcon/>
                                        </div>

                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage={<span>Кол-во эл/стр:</span>}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                    colSpan={7}
                                    count={count}
                                    rowsPerPage={limit}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeLimit}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
                <div className="applications__mob-list">
                    <Card>
                        <PerfectScrollbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{width: 80}} style={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "15px",
                                            letterSpacing: "1px",
                                            color: "#5E5873",
                                            fontFamily: 'Poppins, sans-serif',
                                            minWidth: 90
                                        }}
                                                   align="center"
                                        >
                                            № Заявки
                                        </TableCell>
                                        <TableCell style={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "15px",
                                            letterSpacing: "1px",
                                            color: "#5E5873",
                                            fontFamily: 'Poppins, sans-serif',
                                            minWidth: 90
                                        }}
                                                   align="center"
                                        >
                                            Статус
                                        </TableCell>
                                        <TableCell style={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "15px",
                                            letterSpacing: "1px",
                                            color: "#5E5873",
                                            fontFamily: 'Poppins, sans-serif',
                                            minWidth: 90
                                        }}
                                                   align="center"
                                        >
                                            Сообщение
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {applications?.map((item) => (

                                        <TableRow key={item?._id} hover={false}>
                                            <TableCell style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "21px",
                                                color: "#6E6B7B",
                                                fontFamily: 'Poppins, sans-serif',
                                                minWidth: 90,
                                                verticalAlign: 'top',
                                                marginTop: 20
                                            }}
                                                       align="center"
                                            >
                                                Заявка №{item?.id || '---'}
                                            </TableCell>
                                            <TableCell style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "21px",
                                                color: "#6E6B7B",
                                                fontFamily: 'Poppins, sans-serif',
                                                minWidth: 90,
                                                verticalAlign: 'top',
                                                marginTop: 20
                                            }}
                                                       align="center"
                                            >
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <div>
                                                        {item?.status || '---'}
                                                    </div>
                                                    <div style={{marginTop: 20}}>
                                                        <Link to={`/app/main/edit-report/${item.id}`}>
                                                            <ShowMoreButton/>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "21px",
                                                color: "#6E6B7B",
                                                fontFamily: 'Poppins, sans-serif',
                                                minWidth: 90,
                                                verticalAlign: 'top',
                                                marginTop: 20
                                            }}
                                                       align="center"
                                            >
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <div>
                                                        {item?.message || "---"}
                                                    </div>
                                                    <div style={{marginTop: 20, cursor: "pointer"}}
                                                         onClick={() => setModalStatus({
                                                             id: item.id,
                                                             isActive: true
                                                         })}
                                                    >
                                                        <ShowFullInfoIcon/>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            labelRowsPerPage={<span>Эл/Стр:</span>}
                                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                            colSpan={7}
                                            count={count}
                                            rowsPerPage={limit}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeLimit}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </PerfectScrollbar>
                    </Card>
                </div>

            </div>
            {modalStatus.id !== 0 &&
            <Modal modalStatus={modalStatus} setModalStatus={setModalStatus}/>
            }
            {isLoading && (
                <Loader/>
            )}
        </>
    );
};

export default Сustomers;
