import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setTime} from "../../redux/timer";

const Stopwatch = ({
                       running,
                       houses,
                       minutes,
                       seconds
                   }) => {

    return (
        <div className="stopwatch">
            <div className="numbers">
                <span>{houses <= 9 ? `0${houses}`: houses}:</span>
                <span>{minutes <= 9 ? `0${minutes}`: minutes}:</span>
                <span>{seconds <= 9 ? `0${seconds}`: seconds}</span>
            </div>
        </div>
    );
};

export default Stopwatch;



