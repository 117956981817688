import "./index.scss";
import Nav from "../../../components/Nav";
import Button from "../../../components/Button";
import ReportInput from "../../../components/ReportInput";
import Input from "../../../components/Input";
import {useEffect, useState} from "react";
import {HashLink} from "react-router-hash-link";
import {Link, useNavigate} from "react-router-dom";
import Selector from "../../../components/Selector";
import Textarea from "../../../components/Textarea";
import {useGet, usePost} from "../../../api/request";
import Loader from "../../../components/Loader";
import GlobalError from "../../../components/GlobalError";
import {useAlert} from "../../../components/AlertNotification";

const CreateReport = () => {

    const navigate = useNavigate();
    const getU = useGet();
    const postU = usePost();
    const alertU = useAlert();

    const [isLoading, setIsLoading] = useState(true);


    const [values, setValues] = useState({
        message: '',
        link: '',
        platform: '',
        theme: '',
        cost: '',
        leadTime: '',
    })

    const [errors, setErrors] = useState({
        message: '',
        link: '',
        platform: '',
        theme: '',
        cost: '',
        leadTime: '',
    })

    const [listOfPlatforms, setListOfPlatforms] = useState([])
    const [listOfTheme, setListOfTheme] = useState([])

    const handleChangeValues = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: ''
        });
    };

    const handleChangeTheme = (name) => {
        setValues({
            ...values,
            theme: name
        });
        setErrors({
            ...errors,
            theme: ''
        });
    };

    const handleChangePlatform = (name) => {
        setValues({
            ...values,
            platform: name
        });
        setErrors({
            ...errors,
            platform: ''
        });
    };


    const validation = () => {
        let isValid = true;
        let formErrors = {...errors};
        if (values.message === '') {
            formErrors.message = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.link === '') {
            formErrors.link = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.platform === '') {
            formErrors.platform = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.theme === '') {
            formErrors.theme = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.cost === '') {
            formErrors.cost = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.leadTime === '') {
            formErrors.leadTime = 'Поле должно быть запосленно';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid
    }


    const onSave = () => {
        if (!validation()) return;

        setIsLoading(true)

        const data = {
            message: values.message,
            link: values.link,
            platform: values.platform,
            theme: values.theme,
            cost: values.cost,
            leadTime: values.leadTime,
        }

        postU('applications', data)
            .then((response) =>{
                if (response.status === "success"){
                    alertU({
                        status: 'success',
                        title: 'Создание актеты',
                        message: 'Вы успешно создали новую заявку'
                    });
                    setValues({
                        message: '',
                        link: '',
                        platform: '',
                        theme: '',
                        cost: '',
                        leadTime: '',
                    })
                    navigate('/app/main')
                }
            })
            .catch(() =>{
                alertU({
                    status: 'error',
                    title: 'Получение заявок',
                    message: 'Произошла ошибка при создании анкеты'
                });
            })
            .finally(() =>{
                setIsLoading(false)
            })
    }

    const loadLists = async () => {
        await getU("platforms?page=1&limit=100")
            .then((response) =>{
                if (response.status === "success"){
                    setListOfPlatforms(response.data.platforms)
                }
            })
            .catch(() =>{
                alertU({
                    status: 'error',
                    title: 'Получение пллатформ',
                    message: 'Произошла ошибка при попытке получить платформы'
                });
            })
            .finally(() =>{

            })
        await getU("themes?page=1&limit=100")
            .then((response) =>{
                if (response.status === "success"){
                    setListOfTheme(response.data.themes)
                }
            })
            .catch(() =>{
                alertU({
                    status: 'error',
                    title: 'Получение тем',
                    message: 'Произошла ошибка при попытке получить темы'
                });
            })
            .finally(() =>{
                setIsLoading(false)
            })
    }

    useEffect(() => {
        loadLists();
    }, [])

    return (
        <>
            <div className="main">
                <Nav/>
                <div className="main__form">
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <Textarea
                                name="message"
                                title="Сообщение"
                                value={values.message}
                                onChange={handleChangeValues}
                                placeholder="Введите сообщение"
                                error={errors.message}
                            />
                        </div>
                    </div>
                    <div className="main__form-two-inputs-block">
                        <div className="main__form-input">
                            <ReportInput
                                name="link"
                                title="Ссылка на заявку"
                                value={values.link}
                                onChange={handleChangeValues}
                                placeholder="Введите ссылку на заявку"
                                error={errors.link}
                            />
                        </div>
                        <div className="main__form-input">
                            <ReportInput
                                name="leadTime"
                                title="Срок исполнения(дни)"
                                value={values.leadTime}
                                type="number"
                                onChange={handleChangeValues}
                                placeholder="Введите ссылку на заявку"
                                error={errors.leadTime}
                            />
                        </div>
                    </div>
                    <div className="main__form-two-inputs-block">
                        <div className="main__form-input">
                            <Selector
                                title="Тема"
                                name="theme"
                                onChange={handleChangeTheme}
                                value={values.theme}
                                error={errors.theme}
                                placeholder="Выберите тему"
                                listOfCountry={listOfTheme}
                            />

                        </div>
                        <div className="main__form-input">
                            <Selector
                                title="Платформа"
                                name="country"
                                onChange={handleChangePlatform}
                                value={values.platform}
                                error={errors.platform}
                                placeholder="Выберите платформу"
                                listOfCountry={listOfPlatforms}
                            />
                        </div>
                    </div>
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <ReportInput
                                name="cost"
                                title="Цена"
                                type="number"
                                value={values.cost}
                                onChange={handleChangeValues}
                                placeholder="Введите цену"
                                error={errors.cost}
                            />
                        </div>
                    </div>

                    <div className="main__form__buttons">
                        <button className="main__form__buttons-save" onClick={onSave}>
                            Создать
                        </button>

                        <Link to="/app/main">
                            <button className="main__form__buttons-discard">
                                Выйти
                            </button>
                        </Link>
                    </div>
                </div>
                    {isLoading && (
                        <Loader/>
                    )}
            </div>
        </>
    );
};

export default CreateReport;
