import "./index.scss";
import Input from "../../components/Input";
import {Link, useNavigate} from "react-router-dom";
import CheckBox from "../../components/CheckBox";
import {setRememberData, setTokens, setUser} from "../../redux/auth";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import ButtonFilled from "../../components/ButtonFilled";
import TokenStorage from "../../api/TokenStorage";
import {useLocation} from "react-router-dom";

const Nav = () => {
    const location = useLocation();

    const isMob = false;

    const navElement = [
        {
            href: '/app/main',
            title: 'Отчет'
        },
        {
            href: '/app/applications',
            title: 'Заявки'
        },
        {
            href: '/app/customers',
            title: 'Клиенты'
        }
    ];
    return (

        <ul className={`navbar ${isMob ? 'navbar_mob' : ''}`}>
            {
                navElement.map((item) =>
                    <li className="navbar__item">
                        <Link className={`navbar__link ${location.pathname.includes(item.href) ? 'active' : ''}`} to={item.href}>
                            {
                                item.title
                            }
                        </Link>
                    </li>
                )
            }
        </ul>

    );
};

export default Nav;
