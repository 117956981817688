import "./index.scss";
import Nav from "../../components/Nav";
import Button from "../../components/Button";
import ReportInput from "../../components/ReportInput";
import Input from "../../components/Input";
import React, {useEffect, useState} from "react";
import {HashLink} from "react-router-hash-link";
import {Link, useNavigate, useParams} from "react-router-dom";
import Selector from "../../components/Selector";
import Textarea from "../../components/Textarea";
import {useGet, usePost, usePut} from "../../api/request";
import Loader from "../../components/Loader";
import GlobalError from "../../components/GlobalError";
import {useAlert} from "../../components/AlertNotification";

const ShareInfo = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();
    const alertU = useAlert();


    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(true);

    const [values, setValues] = useState({
        link: '',
        platform: '',
        theme: '',
        cost: '',
        leadTime: '',
        comment: '',
        message: '',
        phone: '',
        email: '',
        anotherContact: ''
    })

    const [errors, setErrors] = useState({
        link: '',
        platform: '',
        theme: '',
        cost: '',
        leadTime: '',
        comment: '',
        message: '',
        phone: '',
        email: '',
        anotherContact: ''
    })

    const [listOfPlatforms, setListOfPlatforms] = useState([])
    const [listOfTheme, setListOfTheme] = useState([])

    const [alert, setAlert] = useState({
        txt: '',
        type: 'error',
        isVisible: false
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type: 'error',
                isVisible: false
            });
        }, 2500);
    };

    const handleChangeValues = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: ''
        });
    };

    const handleChangeTheme = (title) => {
        setValues({
            ...values,
            theme: title
        });
        setErrors({
            ...errors,
            theme: ''
        });
    };

    const handleChangePlatform = (title) => {
        setValues({
            ...values,
            platform: title
        });
        setErrors({
            ...errors,
            platform: ''
        });
    };



    const validation = () => {
        let isValid = true;
        let formErrors = {...errors};

        if (values.link === '') {
            formErrors.link = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.platform === '') {
            formErrors.platform = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.theme === '') {
            formErrors.theme = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.cost === '') {
            formErrors.cost = 'Поле должно быть запосленно';
            isValid = false;
        }


        if (values.leadTime === '') {
            formErrors.leadTime = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.comment === '') {
            formErrors.comment = 'Поле должно быть запосленно';
            isValid = false;
        }
        if (values.phone === '') {
            formErrors.phone = 'Поле должно быть запосленно';
            isValid = false;
        }
        if (values.email === '') {
            formErrors.email = 'Поле должно быть запосленно';
            isValid = false;
        }
        if (values.anotherContact === '') {
            formErrors.anotherContact = 'Поле должно быть запосленно';
            isValid = false;
        }


        setErrors(formErrors);
        return isValid
    }


    const onSave = () => {
        if (!validation()) return;


        const data = {
            message: values.message,
            link: values.link,
            platform: values.platform,
            theme: values.theme,
            cost: values.cost,
            leadTime: values.leadTime,
            comment: values.comment,
            phone: values.phone,
            email: values.email,
            anotherContact: values.anotherContact
        }

        putU(`applications/${id}`, data)
            .then((response) => {
                if (response.status === "success") {
                    alertU({
                        status: 'success',
                        title: 'Обновление заявки',
                        message: 'Вы успешно обновили заявку'
                    });
                    navigate(-1)
                }
            })
            .catch(() => {
                showAlert("error", "Произошла ошибка при создании анкеты")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const loadLists = async () => {

        await getU(`applications/${id}`)
            .then((response) => {
                if (response.status === 'success') {
                    const data = response.data.application;

                    setValues({
                        message: data.message,
                        link: data.link,
                        platform: data.platform,
                        theme: data.theme,
                        cost: data.cost,
                        currency: data.payment,
                        leadTime: data.leadTime,
                        status: data.status,
                        comment: data.comment,
                        phone: data.phone,
                        email: data.email,
                        anotherContact: data.anotherContact
                    })
                }
            })
            .catch((e) => {
                if (e.response.data.message === "You can't access this application"){
                    showAlert('error', "Вы не можете получить доступ к этому приложению")
                } else{
                    showAlert('error', "Произошла ошибка при попытке получить данные")
                }
            })
            .finally(() => {

            })

        await getU("platforms?page=1&limit=100")
            .then((response) => {
                if (response.status === "success") {
                    setListOfPlatforms(response.data.platforms)
                }
            })
            .catch(() => {
                showAlert('error', "Произошла ошибка при попытке получить платформы")
            })
            .finally(() => {

            })

        await getU("themes?page=1&limit=100")
            .then((response) => {
                if (response.status === "success") {
                    setListOfTheme(response.data.themes)
                }
            })
            .catch(() => {
                showAlert('error', "Произошла ошибка при попытке получить темы")
            })
            .finally(() => {
                setIsLoading(false)
            })


    }

    useEffect(() => {
        loadLists();
    }, [])

    return (
        <>
            <div className="main">
                <Nav/>
                <div className="main__title">
                    Заявка №{id}
                </div>
                <div className="main__form">
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <Textarea
                                name="message"
                                title="Сообщение"
                                value={values.message}
                                onChange={handleChangeValues}
                                placeholder="Введите сообщение"
                                error={errors.message}
                                disabled={isEdit}
                            />
                        </div>
                    </div>
                    <div className="main__form-two-inputs-block">
                        <div className="main__form-input">
                            <ReportInput
                                name="link"
                                title="Ссылка на заявку"
                                value={values.link}
                                onChange={handleChangeValues}
                                placeholder="Введите ссылку на заявку"
                                error={errors.link}
                                disabled={isEdit}
                            />
                        </div>
                        <div className="main__form-input">
                            <ReportInput
                                name="leadTime"
                                title="Срок исполнения(дни)"
                                value={values.leadTime}
                                type="number"
                                onChange={handleChangeValues}
                                placeholder="Введите ссылку на заявку"
                                error={errors.leadTime}
                                disabled={isEdit}
                            />
                        </div>
                    </div>
                    <div className="main__form-two-inputs-block">
                        <div className="main__form-input">
                            <Selector
                                title="Тема"
                                name="theme"
                                onChange={handleChangeTheme}
                                value={values.theme}
                                error={errors.theme}
                                placeholder="Выберите тему"
                                listOfCountry={listOfTheme}
                                disabled={isEdit}
                            />

                        </div>
                        <div className="main__form-input">
                            <Selector
                                title="Платформа"
                                name="country"
                                onChange={handleChangePlatform}
                                value={values.platform}
                                error={errors.platform}
                                placeholder="Выберите платформу"
                                listOfCountry={listOfPlatforms}
                                disabled={isEdit}
                            />
                        </div>
                    </div>
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <ReportInput
                                name="cost"
                                title="Цена"
                                type="number"
                                value={values.cost}
                                onChange={handleChangeValues}
                                placeholder="Введите цену"
                                error={errors.cost}
                            />
                        </div>
                    </div>
                    {isEdit && (values !== '') &&
                    <>
                        <div className="main__form-one-inputs-block">
                            <div className="main__form-input-alone">
                                <ReportInput
                                    name="comment"
                                    title="Комментарий"
                                    value={values.comment}
                                    onChange={handleChangeValues}
                                    placeholder="Введите комментарий"
                                    error={errors.comment}
                                    disabled={isEdit}
                                />
                            </div>
                        </div>
                    </>
                    }
                    <div className="main__form-two-inputs-block">
                        <div className="main__form-input">
                            <ReportInput
                                name="email"
                                title="Почта"
                                value={values.email}
                                onChange={handleChangeValues}
                                placeholder="Введите почту"
                                error={errors.email}
                                disabled={isEdit}
                            />
                        </div>
                        <div className="main__form-input">
                            <ReportInput
                                name="phone"
                                title="Номер телефона"
                                value={values.phone}
                                onChange={handleChangeValues}
                                placeholder="Введите номер телефона"
                                error={errors.phone}
                                disabled={isEdit}
                            />
                        </div>
                    </div>

                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <ReportInput
                                name="anotherContact"
                                title="Другие контакты"
                                value={values.anotherContact}
                                onChange={handleChangeValues}
                                placeholder="Введите другой контакт"
                                error={errors.anotherContact}
                                disabled={isEdit}
                            />
                        </div>
                    </div>

                    <div className="main__form__buttons">
                        {
                            isEdit
                                ? <button className="main__form__buttons-save" onClick={() => setIsEdit(false)}>
                                    Редактировать
                                </button>
                                : <>
                                    <button className="main__form__buttons-save" onClick={onSave}>
                                        Сохранить
                                    </button>
                                    <button className="main__form__buttons-discard" onClick={() => setIsEdit(true)}>
                                        Назад
                                    </button>
                                </>
                        }
                        <button className="main__form__buttons-discard" onClick={() => navigate(-1)}>
                            Выйти
                        </button>
                    </div>
                </div>
                {alert.isVisible &&
                <GlobalError isVisible={alert.isVisible} type={alert.type}>
                    {alert.txt}
                </GlobalError>
                }
                {isLoading && (
                    <Loader/>
                )}
            </div>
        </>
    );
};

export default ShareInfo;
