import "./index.scss";
import Input from "../../components/Input";
import {Link, useNavigate} from "react-router-dom";
import CheckBox from "../../components/CheckBox";
import {setRememberData, setTokens, setUser} from "../../redux/auth";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import ButtonFilled from "../../components/ButtonFilled";
import TokenStorage from "../../api/TokenStorage";
import {useLocation} from "react-router-dom";

const UserInfoItem = ({name, value, ...props}) => {

    return (
        <>
            {name === "avatar" || name === "role"
                ? null
                : <div className="user-info-item">
                    <p>
                        {name}:
                        {
                            name === 'status'
                                ? <div className="user-info-item__status">
                                    {
                                        value
                                            ? (
                                                <div className="user-info-item__status-active">
                                                    Active
                                                </div>
                                            )
                                            : (
                                                <div className="user-info-item__status-disactive">
                                                    Not active
                                                </div>
                                            )
                                    }
                                </div>
                                : (
                                    <span>
                                {value}
                            </span>
                                )
                        }
                    </p>
                </div>
            }
            </>
    );
};

export default UserInfoItem;
