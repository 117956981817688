import {useEffect, useState} from "react";
import EditIcon from "../../assets/icons/EditIcon";
import "./index.scss";
import {Link} from "react-router-dom";
import ShowFullInfoIcon from "../../assets/icons/ShowFullInfoIcon";
import EyeIcon from "../../assets/icons/EyeIcon";

const ReportAloneItem = ({id, modalStatus, setModalActive}) => {

    return (
        <div className="report-alone-item">
            <div className="report-alone-item__name">
                Заявка №{id}
            </div>
            <Link to={`/app/main/edit-report/${id}`}>
                <div className="report-alone-item__icon-edit">
                    <EyeIcon/>
                </div>
            </Link>

            <div className="report-alone-item__icon-share"
                 onClick={() => setModalActive({
                     id,
                     isActive: true
                 })}
            >
                <ShowFullInfoIcon/>
            </div>


        </div>
    );
};

export default ReportAloneItem;
