import React, {useEffect, useState} from 'react';
import "./styles.scss"
import ReportInput from "../ReportInput";
import Selector from "../Selector";
import {useNavigate, useParams} from "react-router-dom";
import {useGet, usePut} from "../../api/request";
import GlobalError from "../GlobalError";
import Loader from "../Loader";
import {useAlert} from "../AlertNotification";
import Button from "../Button";

const Confirm = ({modalStatus, setModalStatus}) => {

    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();

    const onClose = () =>{
        setModalStatus({isActive: false})
    }

    const onOpen = () =>{
        setModalStatus({isActive: false})
        navigate('/app/profile')
    }

    return (
        <div className={modalStatus.isActive ? 'confirm-status active' : "confirm-status"}
             onClick={() => setModalStatus({
                 id: 0,
                 isActive: false
             })}
        >
            <div className={modalStatus.isActive ? 'confirm-status__content active' : "confirm-status__content"}
                 onClick={e => e.stopPropagation()}
            >
                <div className="confirm-status__content-title">
                    Предупреждение
                </div>
                <div className="confirm-status__content-desc">
                    При переходе в профиль таймер обнулится и вы не сможете получить плату за это время
                </div>
                <div className="confirm-status__content-buttons">
                    <button className="main__form__buttons-save" onClick={onOpen}>
                        Перейти
                    </button>
                    <button className="main__form__buttons-discard" onClick={onClose}>
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Confirm;
