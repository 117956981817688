import React from "react";

function ArrowDownIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            fill="none"
            viewBox="0 0 19 18"
        >
            <path
    fill="#000"
    d="M14.23 7.275l-4.5 4.5a.68.68 0 01-.525.225.68.68 0 01-.525-.225l-4.5-4.5c-.3-.3-.3-.75 0-1.05.3-.3.75-.3 1.05 0L9.205 10.2l3.975-3.975c.3-.3.75-.3 1.05 0 .3.3.3.75 0 1.05z"
    />
            <mask
                id="mask0_263_131"
                style={{ maskType: "alpha" }}
                width="12"
                height="6"
                x="3"
                y="6"
                maskUnits="userSpaceOnUse"
            >
                <path
    fill="#fff"
    d="M14.23 7.275l-4.5 4.5a.68.68 0 01-.525.225.68.68 0 01-.525-.225l-4.5-4.5c-.3-.3-.3-.75 0-1.05.3-.3.75-.3 1.05 0L9.205 10.2l3.975-3.975c.3-.3.75-.3 1.05 0 .3.3.3.75 0 1.05z"
    />
            </mask>
            <g mask="url(#mask0_263_131)">
                <path fill="#B9B9C3" d="M0.205 0H18.205V18H0.205z"/>
            </g>
        </svg>
    );
}

export default ArrowDownIcon;
