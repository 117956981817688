import "./index.scss";
import Timer from "../../components/Stopwatch";
import { useEffect, useRef, useState } from "react";
import Stopwatch from "../../components/Stopwatch";
import { post, usePost } from "../../api/request";
import GlobalError from "../../components/GlobalError";
import TokenStorage from "../../api/TokenStorage";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/auth";
import { setIsReadyToWork } from "../../redux/work";
import ReactStopwatch from "react-stopwatch";
import { setTime } from "../../redux/timer";
import StopwatchStorage from "../../api/StopwatchStorage";
import { useAlert } from "../../components/AlertNotification";
import { Link } from "react-router-dom";
import StopwatchTextarea from "../../components/StopwatchTextarea";

const TimerSection = () => {
  const postU = usePost();
  const alertU = useAlert();

  const dispatch = useDispatch();
  const user = TokenStorage.getUser();
  const [count, setCount] = useState(user?.thisMonthPay);

  const [isDescription, setIsDescription] = useState(false);
  const [description, setDescription] = useState(null);

  const [running, setRunning] = useState(false);
  const [time, setTime] = useState(0);

  const [alert, setAlert] = useState({
    txt: "",
    isVisible: false,
  });

  const showAlert = (text) => {
    setAlert({
      txt: text,
      isVisible: true,
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        isVisible: false,
      });
    }, 2500);
  };

  const [test, setTest] = useState({
    prevTime: null,
    stopwatchInterval: null,
    elapsedTime: 0,
  });

  const [houses, setHouses] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  var updateTime = function () {
    var tempTime = test.elapsedTime;
    var milliseconds = tempTime % 1000;
    tempTime = Math.floor(tempTime / 1000);
    var seconds = tempTime % 60;
    tempTime = Math.floor(tempTime / 60);
    var minutes = tempTime % 60;
    tempTime = Math.floor(tempTime / 60);
    var hours = tempTime % 60;

    setHouses(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };

  const start = () => {
    const isStopwatchRunning = StopwatchStorage.getIsStopwatchRunning();

    if (isStopwatchRunning) {
      alertU({
        status: "error",
        title: "Таймер уже запущен",
        message: "Остановите предыдущий таймер, перед запуском следующего.",
      });

      return;
    }

    setRunning(true);
    StopwatchStorage.setIsStopwatchRunning(true);

    setTest({
      ...test,
      stopwatchInterval: setInterval(function () {
        if (!test.prevTime) {
          test.prevTime = Date.now();
        }

        test.elapsedTime += Date.now() - test.prevTime;
        test.prevTime = Date.now();

        updateTime();
      }, 50),
    });
  };

  const end = async () => {
    await pause();
    setTest({
      ...test,
      elapsedTime: null,
    });
    setHouses(0);
    setMinutes(0);
    setSeconds(0);
    await updateTime();
  };

  const pause = () => {
    if (test.stopwatchInterval) {
      clearInterval(test.stopwatchInterval);
      setTest({
        ...test,
        stopwatchInterval: null,
      });
    }
    setTest({
      ...test,
      prevTime: null,
    });
  };

  const finish = () => {
    setRunning(false);
    StopwatchStorage.setIsStopwatchRunning(false);

    let milliseconds = seconds * 1000 + minutes * 60000 + houses * 3600000;
    
    if(description == "")
        setDescription(null);

    postU("timetracker", { milliseconds, description })
      .then((response) => {
        if (response.status === "success") {
          TokenStorage.setUser({
            ...user,
            thisMonthPay: response.data.timeTrack.thisMonthPay,
          });
          dispatch(
            setUser({
              ...user,
              thisMonthPay: response.data.timeTrack.thisMonthPay,
            })
          );
          setCount(response.data.timeTrack.thisMonthPay);
          setTime(0);
          end();
        }
      })
      .catch(() => {
        showAlert("Произошла ошибка при попытке остановить время");
      })
      .finally(() => {
        if (test.stopwatchInterval) {
          clearInterval(test.stopwatchInterval);
          setTest({
            ...test,
            stopwatchInterval: null,
          });
        }
        setTest({
          ...test,
          prevTime: null,
        });
      });

    setDescription(null);
    setIsDescription(false);
  };

  const onBeforeUnload = (e) => {
    StopwatchStorage.setIsStopwatchRunning(false);
    if (!running) {
      return;
    }
    var dialogText =
      "Закрыть сайт? Возможно, внесенные изменения не сохранятся.";
    e.returnValue = dialogText;
    return dialogText;
  };

  const handleChangeDescription = (evt) => {
    setDescription(evt.target.value);
  };

  useEffect(() => {
    window.onbeforeunload = onBeforeUnload;
    if (running) {
      dispatch(setIsReadyToWork(true));
    } else {
      dispatch(setIsReadyToWork(false));
    }
  }, [running]);

  return (
    <div className="timer-section">
      <div className="timer-section__timer-block">
        <div
          className="timer-section__timer-block-icon"
          style={{ display: running ? "none" : "flex" }}
          onClick={() => {
            start();
          }}
        >
          <img src="/img/startTimer.png" alt="img" />
        </div>
        <div
          className="timer-section__timer-block-icon"
          style={{ display: running ? "flex" : "none" }}
          onClick={() => {
            finish();
          }}
        >
          <img src="/img/stopTimer.png" alt="img" />
        </div>
        <div className="timer-section__timer-block-timer">
          <Stopwatch
            running={running}
            houses={houses}
            minutes={minutes}
            seconds={seconds}
          />
        </div>
      </div>
      {running && (
        <div className="timer-section__description-block">
          {!isDescription ? (
            <div className="timer-section__button">
              <button
                className="main__form__buttons-save"
                onClick={() => {
                  setIsDescription(true);
                }}
              >
                Добавить описание
              </button>
            </div>
          ) : (
            <StopwatchTextarea onChange={handleChangeDescription} />
          )}
        </div>
      )}
      <div className="timer-section__payment-block">
        <div className="timer-section__payment-block-title">К оплате:</div>
        <div className="timer-section__payment-block-count">{count}</div>
      </div>
      {alert.isVisible && (
        <GlobalError isVisible={alert.isVisible}>{alert.txt}</GlobalError>
      )}
    </div>
  );
};

export default TimerSection;
