import { createSlice } from '@reduxjs/toolkit';
import { configureTransport } from '../../api/transport';

export const counterSlice = createSlice({
    name: 'work',
    initialState: {
        isReadyToWork: false,
    },
    reducers: {
        setIsReadyToWork: (state, action) => {
            state.isReadyToWork = action.payload || false;
        },
    },
});

export const {setIsReadyToWork } = counterSlice.actions;

export default counterSlice.reducer;
