import React from "react";

function EyeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#7367F0"
                d="M10 3.75C5.833 3.75 2.275 6.342.833 10c1.442 3.658 5 6.25 9.167 6.25s7.725-2.592 9.167-6.25c-1.442-3.658-5-6.25-9.167-6.25zm0 10.417A4.168 4.168 0 015.833 10C5.833 7.7 7.7 5.833 10 5.833S14.167 7.7 14.167 10 12.3 14.167 10 14.167zM10 7.5A2.497 2.497 0 007.5 10c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5-1.117-2.5-2.5-2.5z"
            />
        </svg>
    );
}

export default EyeIcon;
