import './styles.scss';

const Button = ({text, onClick, isDisabled}) => (
    <div className="button-wrapper">
        <button className="button" onClick={onClick} disabled={!isDisabled}>
            {text}
        </button>
        <span className="helper-button">
			Для создания отчета включите таймер
		</span>
    </div>

);

export default Button;
