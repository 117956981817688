import { createSlice } from '@reduxjs/toolkit';

export const timerSlice = createSlice({
    name: 'timer',
    initialState: {
        time: 0,
    },
    reducers: {
        setTime: (state, action) => {
            state.time = action.payload || 0;
        },
    },
});

export const {setTime} = timerSlice.actions;

export default timerSlice.reducer;
