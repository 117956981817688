import {
	createStore,
	combineReducers,
	applyMiddleware,
} from 'redux';
import {
	persistStore,
	persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import auth from './redux/auth';
import work from './redux/work';
import timer from './redux/timer';


const appReducer = combineReducers({
	auth,
	work,
	timer
});

const persistConfig = {
	key: 'root-ChinchoTracker',
	storage,
	whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = createStore(persistedReducer);

export default store;
export const persistor = persistStore(store);
