import './styles.scss';
import EditIcon from "../../assets/icons/EditIcon";
import RightArrowIcon from "../../assets/icons/RightArrowIcon";
import EyeIcon from "../../assets/icons/EyeIcon";

const ShowMoreButton = ({ text, onClick }) => (
	<div className="edit-button">
		<p>
			Просмотреть
		</p>
		<EyeIcon/>
	</div>
);

export default ShowMoreButton;
